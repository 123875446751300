export const homeObjOne = {
    id: 'about',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Meu Orçamento',
    headline: 'Direto ao assunto, apenas o que é realmente necessário',
    description: 'Controle seus orçamentos de forma direta e fácil, sem uma infinidade de funcionalidades e menus que você não precisa',
    buttonLabel: 'Comece agora gratuitamente',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}

export const homeObjTwo = {
    id: 'discover',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Acesso Ilimitado',
    headline: 'Direto ao assunto, apenas o que é realmente necessário',
    description: 'Controle seus orçamentos de forma direta e fácil, sem uma infinidade de funcionalidades e menus que você não precisa',
    buttonLabel: 'Saiba mais',
    imgStart: false,
    img: require('../../images/svg-2.svg').default,
    alt: 'Invoice',
    dark: false,
    primary: false,
    darkText: true
}


export const homeObjThree = {
    id: 'signup',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Meu Orçamento',
    headline: 'Direto ao assunto, apenas o que é realmente necessário',
    description: 'Controle seus orçamentos de forma direta e fácil, sem uma infinidade de funcionalidades e menus que você não precisa',
    buttonLabel: 'Comece agora gratuitamente',
    imgStart: true,
    img: require('../../images/svg-3.svg').default,
    alt: 'Car',
    dark: true,
    primary: true,
    darkText: false
}