import React, { useState } from 'react'
import { IconContext } from 'react-icons/lib'
import { GiCutDiamond } from 'react-icons/gi';

import { Button } from '../ButtonElement';
import { 
    PricingCard,
    PricingCardCost,
    PricingCardFeatures,
    PricingCardInfo,
    PricingCardLength,
    PricingCardPlan,
    PricingContainer,
    PricingSection,
    PricingWrapper,
    PricingCardFeature,
    PricingCardIcon,
    PricingHeading
     } from './PricingElements';

const Pricing = () => {    
    return (
        <IconContext.Provider value={{ color: '#fff'}}>
            <PricingSection id="pricing">
                <PricingWrapper>
                    <PricingHeading>Nossos Planos</PricingHeading>
                    <PricingContainer>
                        <PricingCard to="/signup">
                            <PricingCardInfo>
                                <PricingCardIcon>
                                    <GiCutDiamond />
                                </PricingCardIcon>
                                <PricingCardPlan>
                                    Premium
                                </PricingCardPlan>
                                <PricingCardCost>
                                    R$ 999,00
                                </PricingCardCost>
                                <PricingCardLength>
                                    Mensal
                                </PricingCardLength>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Usuários Ilimitados
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Orçamento Ilimitado
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Suporte 24 horas
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                                <Button>Escolher Plano</Button>
                            </PricingCardInfo>
                        </PricingCard>
                        <PricingCard to="/signup">
                            <PricingCardInfo>
                                <PricingCardIcon>
                                    <GiCutDiamond />
                                </PricingCardIcon>
                                <PricingCardPlan>
                                    Premium
                                </PricingCardPlan>
                                <PricingCardCost>
                                    R$ 999,00
                                </PricingCardCost>
                                <PricingCardLength>
                                    Mensal
                                </PricingCardLength>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Usuários Ilimitados
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Orçamento Ilimitado
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Suporte 24 horas
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                                <Button>Escolher Plano</Button>
                            </PricingCardInfo>
                        </PricingCard>
                        <PricingCard to="/signup">
                            <PricingCardInfo>
                                <PricingCardIcon>
                                    <GiCutDiamond />
                                </PricingCardIcon>
                                <PricingCardPlan>
                                    Premium
                                </PricingCardPlan>
                                <PricingCardCost>
                                    R$ 999,00
                                </PricingCardCost>
                                <PricingCardLength>
                                    Mensal
                                </PricingCardLength>
                                <PricingCardFeatures>
                                    <PricingCardFeature>
                                        Usuários Ilimitados
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Orçamento Ilimitado
                                    </PricingCardFeature>
                                    <PricingCardFeature>
                                        Suporte 24 horas
                                    </PricingCardFeature>
                                </PricingCardFeatures>
                                <Button>Escolher Plano</Button>
                            </PricingCardInfo>
                        </PricingCard>
                    </PricingContainer>
                </PricingWrapper>
            </PricingSection>
        </IconContext.Provider>
    );
}

export default Pricing;



