import React from 'react'
import Icon1 from '../../images/svg-4.svg';
import Icon2 from '../../images/svg-5.svg';
import Icon3 from '../../images/svg-6.svg';

import { 
    ServicesContainer,
    ServicesH1,
    ServicesWrapper,
    ServicesCard,
    ServicesIcon,
    ServicesH2,
    ServicesP
} from './ServicesElements';

const Services = () => {
    return (
        <ServicesContainer id="services">
            <ServicesH1>Nossos Serviços</ServicesH1>
            <ServicesWrapper>
                <ServicesCard>
                    <ServicesIcon src={Icon1} />
                    <ServicesH2>Tenha mais controle</ServicesH2>
                    <ServicesP> Nós te ajudamos a ter mais controle de seus orçamentos</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon2} />
                    <ServicesH2>De qualquer lugar</ServicesH2>
                    <ServicesP> Visualize seus dados e emita orçamentos de onde estiver</ServicesP>
                </ServicesCard>
                <ServicesCard>
                    <ServicesIcon src={Icon3} />
                    <ServicesH2>Orçamentos Profissionais</ServicesH2>
                    <ServicesP> Emita orçamentos com sua marca para seus clientes</ServicesP>
                </ServicesCard>                
            </ServicesWrapper>
        </ServicesContainer>
    )
}

export default Services
